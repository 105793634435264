<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="电池macid"
          clearable
          v-model="query.batteryMacId"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-select v-model="query.inOrOut" clearable placeholder="电池状态">
          <el-option
            v-for="(item, index) in this.const.BATTERY_STATUS"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-dropdown>
        <el-button type="primary">
          导入/导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-auth="this.per.BATTERY_IMPORT">
              <UploadExcel :ref="MODAL_KEY.UPLOAD_EXCEL" @submit="uploadData">
                导入数据
              </UploadExcel>
            </el-dropdown-item>

            <el-dropdown-item v-auth="this.per.BATTERY_IMPORT">
              <span @click="doExport"> 导出数据 </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="macid" prop="batteryMacId"></el-table-column>

      <el-table-column label="状态" prop="status"> </el-table-column>

      <el-table-column label="用户" prop="userId"></el-table-column>
<!-- 
      <el-table-column label="备注" prop="remark"> </el-table-column> -->

      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            v-auth="this.per.SHOW_BATTERY_DETAIL"
            @click="showBindUser(scope.row)"
          >
            绑定用户
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <BindUserModal
      :modalData="modalData"
      :ref="MODAL_KEY.BIND_USER"
      @submit="doBindUser"
    ></BindUserModal>
  </div>
</template>

<script>
import { saleBatteryPage, bindUser, importSaleBattery } from "@/api/recharge";
import { exportTemplate } from "@/api/battery";
import BindUserModal from "./components/BindUserModal";
import { mapState } from "vuex";
import moment from "moment";
export default {
  components: { BindUserModal },
  computed: mapState({}),
  data() {
    return {
      dataSource: [],

      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {},
      MODAL_KEY: {
        BIND_USER: "BIND_USER",
      },
      modalData: {},
      total: 0,
    };
  },
  methods: {
    showBindUser(row) {
      this.modalData = {
        batteryMacId: row.batteryMacId,
      };
      this.$refs[this.MODAL_KEY.BIND_USER].showModal();
    },

    doExport() {
      exportDevice({
        ...this.query,
      }).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `所有电池.xlsx`
        );
      });
    },

    doBindUser(data){
      bindUser(data).then(res=>{
        this.getList();
        this.$refs[this.MODAL_KEY.BIND_USER].closeModal();
      })
    },  

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      saleBatteryPage({ ...this.query, ...this.pages }).then((res) => {
        this.dataSource = res.data.data.records;
        this.total = res.data.data.total;
        // this.dataSource.map((item) => {
        //   item.operationName = this.agentList.map[item.operationId];
        // });
      });
    },

    // 下载模板
    downTemplate() {
      exportTemplate().then((res) => {
        this.util.exportFile(res.data, "设备导入模板");
      });
    },

    // 上传文件
    uploadData(data) {
      importSaleBattery(data).then((res) => {
        this.$message.success("导入成功!");
        this.getList();
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
